import { createTheme } from '@material-ui/core/styles';

export default createTheme({
  palette: {
    primary: {
      main: '#0088ED',
      mainLight: '#30B9EF',
    },
    danger: {
      main: '#EB5757',
    },
    background: {
      white: '#ffffff',
      light: '#e5e5e5',
      dark: '#091c2b',
      whiteSmoke: '#F5F5F5',
    },
  },
});
