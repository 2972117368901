import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import {
  AppStateProvider,
  useAppState,
  UnsupportedBrowserWarning,
  ErrorDialog,
  ChatProvider,
  VideoProvider,
  theme as videoTheme,
  useConnectionOptions,
  SocketProvider,
} from '@jaksmok/lovelanguage-common';

import App from './App';
import theme from './theme';

const VideoApp = () => {
  const { error, setError } = useAppState();
  const connectionOptions = useConnectionOptions();

  return (
    <MuiThemeProvider theme={videoTheme}>
      <SocketProvider>
        <VideoProvider options={connectionOptions} onError={setError}>
          <ErrorDialog dismissError={() => setError(null)} error={error} />
          <ChatProvider>
            <App />
          </ChatProvider>
        </VideoProvider>
      </SocketProvider>
    </MuiThemeProvider>
  );
};

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <UnsupportedBrowserWarning>
      <Router>
        <AppStateProvider>
          <Switch>
            <Route exact path="/">
              <VideoApp />
            </Route>
            <Route path="/join/:roomId">
              <VideoApp />
            </Route>
            <Redirect to="/" />
          </Switch>
        </AppStateProvider>
      </Router>
    </UnsupportedBrowserWarning>
  </MuiThemeProvider>,
  document.getElementById('root')
);
