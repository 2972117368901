import {
  MenuBar,
  MobileTopMenuBar,
  PreJoinScreens,
  ReconnectingNotification,
  RecordingNotifications,
  Room,
  useAppState,
  useHeight,
  useRoomState,
} from '@jaksmok/lovelanguage-common';
import { Box, Grid, Hidden } from '@material-ui/core';
import { makeStyles, styled } from '@material-ui/core/styles';

import logo from 'assets/360Live-192.png';
import clsx from 'clsx';
import React from 'react';
import dots from './assets/dots.png';
import lobbyImage from './assets/lobby-image.jpg';

const useStyle = makeStyles((theme) => ({
  main: {
    display: 'grid',
    gridTemplateRows: '1fr auto',
  },
  preJoinContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  whiteBackground: {
    background: '#ffffff',
  },
  darkBackground: {
    background: '#091c2b',
  },
}));

const CallScreen = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  paddingBottom: `${theme.footerHeight}px`, // Leave some space for the footer
  background: theme.palette.background.dark,
  [theme.breakpoints.down('sm')]: {
    paddingBottom: `${theme.mobileFooterHeight + theme.mobileTopBarHeight}px`, // Leave some space for the mobile header and footer
  },
}));

const Logo = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  margin: '20px',
  marginLeft: 85,
  color: 'white',
  '& img': {
    maxHeight: 100,
  },
}));

const Image = styled('img')(({ theme }) => ({
  width: '623px',
  height: '440px',
  [theme.breakpoints.down('lg')]: {
    width: '523px',
    height: '340px',
  },
}));

const DotImageResponsiveContainer = styled('div')(({ theme }) => ({
  width: '623px',
  display: 'flex',
  justifyContent: 'flex-end',
  [theme.breakpoints.down('lg')]: {
    width: '523px',
  },
}));
export default function App() {
  const classes = useStyle();
  const roomState = useRoomState();
  const { callStep } = useAppState();
  // Here we would like the height of the main container to be the height of the viewport.
  // On some mobile browsers, 'height: 100vh' sets the height equal to that of the screen,
  // not the viewport. This looks bad when the mobile browsers location bar is open.
  // We will dynamically set the height with 'window.innerHeight', which means that this
  // will look good on mobile browsers even after the location bar opens or closes.
  const height = useHeight();

  return (
    <main
      className={clsx(classes.main, {
        [classes.whiteBackground]: callStep === 'guestNameStep',
        [classes.darkBackground]: callStep === 'deviceSelectionStep',
      })}
      style={{ height }}
    >
      {roomState === 'disconnected' ? (
        <div
          className={clsx(classes.preJoinContainer, {
            [classes.whiteBackground]: callStep === 'guestNameStep',
            [classes.darkBackground]: callStep === 'deviceSelectionStep',
          })}
        >
          {callStep === 'guestNameStep' ? (
            <>
              <Logo position="absolute" left={0} top={0}>
                <img src={logo} alt="360Live logo" />
              </Logo>
              <Box component={Grid} container display="flex" alignItems="center">
                <Grid item md={6} xs={12}>
                  <PreJoinScreens />
                </Grid>
                <Box component={Grid} item md={6} justifyContent="center">
                  <Hidden smDown>
                    <Box component="img" src={dots} alt="" />
                    <div>
                      <Image src={lobbyImage} alt="lobbyImage" />
                    </div>
                    <DotImageResponsiveContainer>
                      <img src={dots} alt="" />
                    </DotImageResponsiveContainer>
                  </Hidden>
                </Box>
              </Box>
            </>
          ) : (
            <div
              className={clsx({
                [classes.whiteBackground]: callStep === 'guestNameStep',
              })}
            >
              <PreJoinScreens />
            </div>
          )}
        </div>
      ) : (
        <CallScreen>
          <ReconnectingNotification />
          <RecordingNotifications />
          <MobileTopMenuBar />
          <Room />
          <MenuBar />
        </CallScreen>
      )}
    </main>
  );
}
